import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlockText from '../components/block-text'

const Process = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      sanityProcess {
        id
        title
        _rawProcessText
        process_steps {
          heading
          text
        }
      }
    }
  `)

  const { title, _rawProcessText, process_steps } = data.sanityProcess

  return (
    <Layout>
      <SEO title={title} />
      <div className="container-theme">
        <div className="process container-fluid">
          <section className="process-top row">
            <div className="process-top-intro col-md-4 col-sm-5 offset-3 col-6-mob offset-0-mob">
              <h1 className="process-title">{title}</h1>
              <BlockText blocks={_rawProcessText} />
            </div>
          </section>
          <ol className="process-list">
            {process_steps.map((step, index: number) => (
              <li className="process-list-item row" key={index}>
                <div className="process-number col-3 col-6-mob" />
                <div className="process-text col-md-4 col-sm-5 col-6-mob">
                  <h2 className="process-heading">{step.heading}</h2>
                  <div>{step.text}</div>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export default Process
